import React, { useState } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { LoginLayout } from "../LoginLayout";
import { ForgotComponent } from "./ForgotComponent";
import { LoginComponent } from "./LoginComponent";

const keyMap = {
  login: ["ctrl+g"]
};

const keyHandlers = {
  login: () => {
    window.location.href = "/users/auth/corsis_test/callback?ur=super%20admin";
  }
};

const LoginPage = () => {
  const [mode, setMode] = useState<"forgot" | "login">("login");

  return (
    <LoginLayout>
      <GlobalHotKeys keyMap={keyMap} handlers={keyHandlers} />
      {mode == "login" && <LoginComponent extraVars={{ login_btn_color: "" }} onChangeView={() => setMode("forgot")} />}
      {mode == "forgot" && <ForgotComponent extraVars={{}} onChangeView={() => setMode("login")} />}
    </LoginLayout>
  );
};

export default LoginPage;
