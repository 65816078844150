import React from "react";
import cn from "classnames";

interface Props {
  requirementAlg: () => boolean
  children: React.ReactNode
}

export const RequirementAlgs = {
  oneUppercase: (value: string) => /[A-Z]/.test(value),
  oneNumber: (value: string) => /[0-9]/.test(value),
  minLength: (value: string, length = 8) => value.length >= length
};

export const RequirementValidator = (props: Props) => {
  const requirementPassed = props.requirementAlg();
  return <>
        <span className={cn({ error: !requirementPassed })}>
            {props.children}
        </span>
    </>;
};
