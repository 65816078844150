import gql from "graphql-tag";
import { Checkbox, DefaultButton, MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import React from "react";
import { gqlMutation } from "../../common/lib";
import { logout } from "../../reports/common";

export class M2faComponentBase extends React.Component {
  text = "";
  state = {
    rememberMe: false,
    verificationCode: "",
    error: "",
    redirecting: false
  };

  mutation = gql`
    mutation validate_2fa($verificationCode: String!, $rememberMe: Boolean!) {
      validate_2fa(verification_code: $verificationCode, remember_me: $rememberMe) {
        success
        fingerprint
        redirect_to
      }
    }
  `;

  submit = (mutateFunc) => {
    const { verificationCode, rememberMe } = this.state;
    mutateFunc({
      variables: {
        verificationCode,
        rememberMe
      }
    }).then(({ data }) => {
      if (!data.validate_2fa.success) {
        this.setState({ error: "Could not validate token." });
        return;
      }
      this.setState({ redirecting: true });
      const { fingerprint, redirect_url } = data.validate_2fa;
      document.cookie = `device_id=${fingerprint}; path=/`;
      window.location.href = redirect_url || "/admin/";
    });
  };

  render() {
    if (this.state.redirecting) {
      return null;
    }
    return gqlMutation(this.mutation, (mutateFunc) => (
      <div className={"m2fa-page"}>
        <div className="corsis-logo" />
        <span className="subtitle">2FA</span>
        <p>{this.text}</p>
        {this.state.error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {this.state.error}
          </MessageBar>
        )}
        <form onSubmit={() => this.submit(mutateFunc)}>
          <TextField onChange={(e, verificationCode) => this.setState({ verificationCode })} autoFocus />
          <Checkbox
            defaultChecked={this.state.rememberMe}
            onChange={(e, rememberMe) => this.setState({ rememberMe })}
            label={"Recognize this device in the future"}
          />
        </form>
        <DefaultButton onClick={() => logout()}>Cancel</DefaultButton>
        <PrimaryButton onClick={() => this.submit(mutateFunc)}>Submit</PrimaryButton>
      </div>
    ));
  }
}
