import React, { useEffect } from "react";
import PubSub from "pubsub-js";
import CorsisLogo from "../../common/lib/CorsisLogo";
import cn from "classnames";

interface SessionPaths {
  terms_conditions: string
  privacy_policy: string
}

const LinkedInContent = () => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      iframe.onload = () => {
        const script = document.createElement("script");
        script.src = "https://widgets.sociablekit.com/linkedin-page-posts/widget.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
          PubSub.publish("linkedin-content-loaded", {});
        };

        // Append the script to the iframe"s document
        iframe.contentWindow?.document.body.appendChild(script);
        const div = document.createElement("div");
        div.className = "sk-ww-linkedin-page-post";
        div.setAttribute("data-embed-id", "142203");
        iframe.contentWindow?.document.body.appendChild(div);
      };
      iframe.onload(new Event("load"));
    }
  }, []); // Empty dependency array to run effect once at mount

  return (
      <iframe ref={iframeRef} className={"linkedin-iframe"}/>
  );
};

export const LoginLayout = ({ withLinkedIn = true, children }) => {
  const paths = window.constants.paths as SessionPaths;
  const [showLogo, setShowLogo] = React.useState(true);

  React.useEffect(() => {
    PubSub.subscribe("linkedin-content-loaded", () => {
      setShowLogo(false);
    });
    return () => {
      PubSub.unsubscribe("linkedin-content-loaded");
    };
  });

  return (
    <div className="login-page-container">
      <div className="container">
        <div className="left">
          <div className="login-component">
            {children}
            <div className="footer">
              <a href={paths.terms_conditions} target="_blank" rel="noreferrer">
                Terms
              </a>
              |
              <a href={paths.privacy_policy} target="_blank" rel="noreferrer">
                Privacy
              </a>
            </div>
          </div>
        </div>
        <div
          className={cn("right", { "linkedin-loaded": !showLogo })}
          style={{
            backgroundSize: "cover"
          }}
        >
          {showLogo &&
            <div className="featured-content-logo">
              <CorsisLogo width={295} height={62} crosslakeOnly monochrome />
            </div>
          }
          {withLinkedIn &&
            <div className="featured-content-container">
              <div className="featured-content">
                <LinkedInContent />
              </div>
          </div>
          }
        </div>
      </div>
    </div>
  );
};
