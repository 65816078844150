import { ForgotPasswordMutation } from "gql/mutations/auth.graphql";
import { TextField } from "office-ui-fabric-react";
import React from "react";
import { LoginComponent } from "./LoginComponent";

export class ForgotComponent extends LoginComponent {
  render_welcome = false;
  submit_label = "Submit";
  link_label = "Back to login";
  mutation = ForgotPasswordMutation;

  submit(mutateFunc) {
    const { email } = this.state;
    mutateFunc({ variables: { email } })
      .then(({ data }) => {
        this.setState({ completed: true, email: "" });
      })
      .catch((res) => {
        this.setState({ error: JSON.parse(res.graphQLErrors[0].message)[0] });
      });
  }

  formInner() {
    if (this.state.completed) {
      return null;
    }

    return (
      <>
        <strong className="forgot-text">Forgot your password?</strong>
        <ul>
          <li>Passwords are case sensitive.</li>
          <li>Your account may require the Google login.</li>
        </ul>
        <span className="reset-text">
          To reset your password, enter the email address below associated with your account.
        </span>
        <TextField
          autoFocus
          label="Email"
          value={this.state.email}
          onChange={(e, email) => this.setState({ email })}
          data-testid="email"
        />
      </>
    );
  }
}
