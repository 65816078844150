import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import React, { useState } from "react";
import { LoginLayout } from "../LoginLayout";
import { ERROR_MESSAGES } from "./constants";
import EmailForm from "./EmailForm";
import LoginForms from "./LoginForms";

const ConfirmationMessage = () => {
  return <>
      <h1>Activate Your Account</h1>
      <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
        Account activation email sent. <br />
        Check your inbox for instructions.
      </MessageBar>
    </>;
};

const ClientLoginPage = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const errorCode = params.get("error_code") ?? "";

  const [error, setError] = useState(errorCode ? ERROR_MESSAGES[errorCode] ?? "Failed to login" : "");
  const [options, setOptions] = useState({
    clientEmail: params.get("client_email") ?? "",
    requireOauth: params.get("require_oauth") === "true",
    isConfirmed: params.get("is_confirmed") === "true"
  });
  const redirectTo = params.get("redirect_to") ?? "";

  return (
    <LoginLayout>
      <div className="h-100 flex items-center justify-center">
        <div className="w-360px min-h-500px p-16 flex flex-col">
          <div className="text-32 text-darkgray">
            Welcome to Crosslake's
            <br />
            Corsis&#0174; application!
          </div>

          <div className="py-12">
            {error && (
              <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                {error}
              </MessageBar>
            )}
          </div>

          <div className="flex-1 overflow-hidden">
            {!options.clientEmail &&
              <EmailForm
                onSubmit={(data) => {
                  setOptions({
                    clientEmail: data.email, isConfirmed: data.is_confirmed, requireOauth: data.require_oauth
                  });
                  setError("");
                }}
                onError={setError}
              />
            }
            {options.clientEmail && options.isConfirmed &&
              <LoginForms
                email={options.clientEmail}
                requireOauth={options.requireOauth}
                redirectTo={redirectTo}
                onError={setError}
              />
            }
            {options.clientEmail && !options.isConfirmed &&
                <ConfirmationMessage />
            }
          </div>

          <div className="mt-24 text-center text-darkgray text-14">
            For the best experience use
            <a
              className="text-blue font-bold ml-4 mr-0"
              target="_blank"
              href="https://www.google.com/chrome/"
              rel="noreferrer"
            >
              Google Chrome
            </a>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default ClientLoginPage;
