import { ApolloProvider } from "@apollo/client";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { createBrowserHistory } from "history";
import React from "react";
import { Redirect, Route, Router, Switch } from "react-router";
import initIcons from "../init-fabric-icons";
import ClientLoginPage from "./ClientLoginPage";
import ConfirmationPage from "./ConfirmationPage";
import LoginPage from "./LoginPage";
import M2FAPage from "./M2FAPage";
import ResetPassword from "./ResetPassword";
import "./sessions.scss";

initIcons();

library.add(fal);

const history = createBrowserHistory();

export default class SessionRouter extends React.Component {
  private readonly router = React.createRef<Router>();

  render() {
    return (
      <ApolloProvider client={window.apolloClient}>
        <Router history={history} ref={this.router}>
          <Switch>
            <Route exact path="/users/sign_in" component={LoginPage} />
            <Route exact path="/users/m2fa" component={M2FAPage} />
            <Route exact path="/users/confirmation" component={ConfirmationPage} />
            <Route exact path="/users/password/edit" component={ResetPassword} />
            <Route exact path="/clients/auth/sign_in" component={ClientLoginPage} />
            <Redirect exact from="/" to="/users/sign_in" />
          </Switch>
        </Router>
      </ApolloProvider>
    );
  }
}
