import * as React from "react";
const CorsisLogo = (props) => {
  const width = props.width || "100%";
  const height = props.height || "100%";
  const byCorsis = !props.crosslakeOnly;
  const colors = props.monochrome ? ["black", "white", "white"] : ["#0082de", "#7c7c7c", "#303030"];
  return (
        <svg
            id="Layer_2"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width, height }}
            {...props}
        >
            <defs>
                <style>
                    {
                        `.cls-1{fill:${colors[0]};}` +
                        `.cls-2{fill:${colors[1]};}` +
                        `.cls-3{fill:${colors[2]};}`
                    }
                </style>
            </defs>
            <g id="_40_HORIZ_Hurme_AC_copy">
                <g>
                    <g>
                        <path
                            className="cls-3"
                            d="M278.53,15.05h-1.8v-.43h4.08v.43h-1.81v5.79h-.46v-5.79Z"
                        />
                        <path
                            className="cls-3"
                            d="M288,14.48v6.36h-.45v-4.84l-2.44,3.8-2.44-3.8v4.84h-.45v-6.36l2.89,4.54,2.89-4.54Z"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-3"
                            d="M14.12,26.42c4.23,0,7.94,1.83,10.53,4.77l-3.24,2.79c-1.79-2.02-4.35-3.24-7.29-3.24-5.34,0-9.38,4.04-9.38,9.46s4.04,9.42,9.38,9.42c2.94,0,5.57-1.22,7.36-3.32l3.2,2.79c-2.56,3.01-6.33,4.85-10.57,4.85-7.82,0-14.12-5.8-14.12-13.73s6.29-13.77,14.12-13.77Z"
                        />
                        <path
                            className="cls-3"
                            d="M31.86,26.88h10.34c4.73,0,8.62,3.62,8.62,8.43,0,3.51-1.98,6.37-4.85,7.55l6.45,10.61h-5.38l-5.68-9.46h-4.73v9.46h-4.77V26.88Zm9.84,12.86c2.59,0,4.39-1.79,4.39-4.35s-1.72-4.2-4.01-4.2h-5.46v8.55h5.07Z"
                        />
                        <path
                            className="cls-3"
                            d="M71.73,26.42c7.82,0,14.08,5.99,14.08,13.81s-6.26,13.77-14.08,13.77-14.04-5.95-14.04-13.77,6.26-13.81,14.04-13.81Zm0,23.27c5.3,0,9.35-4.12,9.35-9.46s-4.04-9.5-9.35-9.5-9.35,4.16-9.35,9.5,4.04,9.46,9.35,9.46Z"
                        />
                        <path
                            className="cls-3"
                            d="M94.96,45.61c1.22,2.4,3.47,4.04,6.68,4.04,3.7,0,4.5-2.17,4.5-3.47,0-2.4-2.17-3.32-5.42-4.54-4.27-1.6-7.63-3.36-7.63-8.05,0-4.39,3.74-7.17,8.24-7.17,4.23,0,6.98,1.87,8.62,4.35l-3.51,2.4c-.99-1.56-2.59-2.48-5.04-2.48-2.1,0-3.62,1.18-3.62,2.82,0,2.44,2.14,2.86,6.03,4.46,4.84,1.95,7.1,4.01,7.1,8.09,0,4.5-3.05,7.86-9.31,7.86-5.07,0-8.58-2.4-10.22-6.07l3.59-2.25Z"
                        />
                        <path
                            className="cls-3"
                            d="M119.92,45.61c1.22,2.4,3.47,4.04,6.68,4.04,3.7,0,4.5-2.17,4.5-3.47,0-2.4-2.17-3.32-5.42-4.54-4.27-1.6-7.63-3.36-7.63-8.05,0-4.39,3.74-7.17,8.24-7.17,4.23,0,6.98,1.87,8.62,4.35l-3.51,2.4c-.99-1.56-2.59-2.48-5.04-2.48-2.1,0-3.62,1.18-3.62,2.82,0,2.44,2.14,2.86,6.03,4.46,4.84,1.95,7.1,4.01,7.1,8.09,0,4.5-3.05,7.86-9.31,7.86-5.07,0-8.58-2.4-10.22-6.07l3.59-2.25Z"
                        />
                        <path
                            className="cls-3"
                            d="M143.42,26.88h4.77v22.28h10.57v4.31h-15.34V26.88Z"
                        />
                        <path
                            className="cls-3"
                            d="M174.78,26.88h4.04l11.1,26.59h-5.04l-2.17-5.3h-11.83l-2.17,5.3h-5.04l11.1-26.59Zm6.45,17.36l-4.43-10.83-4.43,10.83h8.85Z"
                        />
                        <path
                            className="cls-3"
                            d="M197.02,26.88h4.77v12.74l11.25-12.74h5.65l-10.34,11.71,10.64,14.88h-5.57l-8.28-11.48-3.36,3.7v7.78h-4.77V26.88Z"
                        />
                        <path
                            className="cls-3"
                            d="M225.79,26.88h16.94v4.31h-12.17v6.33h11.41v4.31h-11.41v7.32h12.44v4.31h-17.21V26.88Z"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-3"
                            d="M261.5,14.36c-3.98,0-7.21,3.25-7.21,7.26v9.63h4.26v-12.59h13.45v-4.29h-10.51Z"
                        />
                        <path
                            className="cls-1"
                            d="M256.74,9.63V0h-4.26V12.59h-13.45v4.29h10.51c3.98,0,7.21-3.25,7.21-7.26Z"
                        />
                    </g>
                </g>
                {byCorsis && <>
                <rect className="cls-2" x={314} width={2} height={72} />
                <g>
                    <path
                        className="cls-2"
                        d="M383.68,30.99l-2.01,1.82c-2.05-2.39-4.75-3.57-8.09-3.57-3.12,0-5.7,1.06-7.75,3.15s-3.08,4.71-3.08,7.87,1.03,5.81,3.08,7.9,4.63,3.12,7.75,3.12c3.42,0,6.15-1.25,8.21-3.72l2.01,1.82c-2.66,3.08-6.08,4.64-10.22,4.64-3.84,0-7.11-1.29-9.8-3.91-2.66-2.62-3.99-5.93-3.99-9.84s1.33-7.18,3.99-9.8c2.7-2.62,5.96-3.95,9.8-3.95,4.07,0,7.45,1.48,10.11,4.48Z"
                    />
                    <path
                        className="cls-2"
                        d="M387.18,40.26c0-3.91,1.33-7.18,3.99-9.8,2.7-2.62,5.96-3.95,9.8-3.95s7.1,1.33,9.76,3.95c2.7,2.62,4.03,5.89,4.03,9.8s-1.33,7.22-4.03,9.84c-2.66,2.62-5.93,3.91-9.76,3.91s-7.11-1.29-9.8-3.91c-2.66-2.62-3.99-5.93-3.99-9.84Zm2.92,0c0,3.15,1.03,5.81,3.08,7.9,2.09,2.09,4.67,3.12,7.79,3.12s5.7-1.03,7.75-3.12c2.05-2.09,3.08-4.75,3.08-7.9s-1.03-5.78-3.08-7.87c-2.05-2.09-4.63-3.15-7.75-3.15s-5.7,1.06-7.79,3.15c-2.05,2.09-3.08,4.71-3.08,7.87Z"
                    />
                    <path
                        className="cls-2"
                        d="M420.43,53.49V27h9.04c2.2,0,4.07,.76,5.62,2.28,1.56,1.48,2.32,3.38,2.32,5.62,0,3.5-2.01,6.34-5.05,7.52l6.38,11.06h-3.38l-5.97-10.49h-6v10.49h-2.96Zm2.96-13.22h5.78c1.52,0,2.77-.49,3.76-1.52,1.03-1.03,1.52-2.28,1.52-3.84,0-2.93-2.17-5.17-5.09-5.17h-5.96v10.52Z"
                    />
                    <path
                        className="cls-2"
                        d="M444.45,46.49c1.33,3.19,3.53,4.79,6.65,4.79,2.24,0,3.84-.76,4.48-1.86,.65-1.1,.84-1.9,.84-2.81,0-1.44-.46-2.55-1.33-3.31-.87-.76-2.43-1.63-4.6-2.58-2.39-1.06-4.18-2.13-5.28-3.15-1.1-1.03-1.67-2.54-1.67-4.48s.68-3.46,2.05-4.71,3.12-1.86,5.21-1.86c3.23,0,5.62,1.41,7.14,4.22l-2.55,1.29c-.99-1.86-2.55-2.77-4.64-2.77-2.39,0-4.25,1.6-4.25,3.76,0,1.37,.34,2.28,1.56,3.19,.65,.42,1.14,.76,1.56,.99l2.28,1.03c2.7,1.18,4.6,2.36,5.74,3.53,1.18,1.14,1.75,2.74,1.75,4.83s-.72,3.95-2.2,5.36c-1.44,1.41-3.5,2.09-6.12,2.09-4.22,0-7.64-2.13-9.16-6.35l2.55-1.18Z"
                    />
                    <path className="cls-2" d="M465.27,53.49V27h2.96v26.48h-2.96Z" />
                    <path
                        className="cls-2"
                        d="M476.37,46.49c1.33,3.19,3.53,4.79,6.65,4.79,2.24,0,3.84-.76,4.48-1.86,.65-1.1,.84-1.9,.84-2.81,0-1.44-.46-2.55-1.33-3.31-.87-.76-2.43-1.63-4.6-2.58-2.39-1.06-4.18-2.13-5.28-3.15-1.1-1.03-1.67-2.54-1.67-4.48s.68-3.46,2.05-4.71,3.12-1.86,5.21-1.86c3.23,0,5.62,1.41,7.14,4.22l-2.55,1.29c-.99-1.86-2.55-2.77-4.64-2.77-2.39,0-4.25,1.6-4.25,3.76,0,1.37,.34,2.28,1.56,3.19,.65,.42,1.14,.76,1.56,.99l2.28,1.03c2.7,1.18,4.6,2.36,5.74,3.53,1.18,1.14,1.75,2.74,1.75,4.83s-.72,3.95-2.2,5.36c-1.44,1.41-3.5,2.09-6.12,2.09-4.22,0-7.64-2.13-9.16-6.35l2.55-1.18Z"
                    />
                </g>
                </>}
            </g>
        </svg>
  );
};
export default CorsisLogo;
