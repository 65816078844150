import * as Sentry from "@sentry/browser";
import { LoginMutation } from "gql/mutations/auth.graphql";
import { Checkbox, MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import React from "react";
import { gqlMutation } from "../../common/lib";
import { get_query_param } from "../../reports/common";
import SocialLoginForms from "../SocialLoginForms";

interface IProps {
  onChangeView: () => void
  extraVars: any
}

export class LoginComponent extends React.Component<IProps> {
  render_welcome = true;
  submit_label = "Login";
  link_label = "Forgot password";
  state = {
    email: "",
    password: "",
    remember_me: false,
    redirecting: false,
    completed: undefined,
    error: undefined
  };

  mutation = LoginMutation;

  componentDidMount() {
    if (get_query_param("error_code") == "1") {
      this.setState({
        error: "E-mail / password combination has not been found."
      });
    }
  }

  submit(mutateFunc) {
    this.setState({ error: "" });
    const { email, password, remember_me } = this.state;
    mutateFunc({ variables: { email, password, remember_me } })
      .then(({ data }) => {
        this.setState({ redirecting: true });
        window.location.href = data.login.redirect_uri;
      })
      .catch((res) => {
        try {
          this.setState({ error: JSON.parse(res.graphQLErrors[0].message)[0] });
        } catch (e) {
          this.setState({ error: "An error has occurred." });
          Sentry.addBreadcrumb({
            level: Sentry.Severity.Error,
            category: "LoginComponent",
            message: JSON.stringify(res)
          });
          throw e;
        }
      });
  }

  formInner(): JSX.Element | null {
    return (
      <>
        <TextField
          data-testid="email"
          name="user_email"
          autoFocus
          label="Email"
          value={this.state.email}
          onChange={(e, email) => this.setState({ email })}
        />
        <TextField
          data-testid="password"
          name="user_password"
          label="Password"
          type="password"
          value={this.state.password}
          onChange={(e, password) => this.setState({ password })}
        />
        <div className="remember-me" data-testid="remember-me-checkbox">
          <Checkbox
            label="Remember me"
            checked={this.state.remember_me}
            onChange={(e, remember_me) => this.setState({ remember_me })}
          />
        </div>
      </>
    );
  }

  render() {
    if (this.state.redirecting) {
      return null;
    }

    const { error, completed } = this.state;
    const canSubmit = Boolean(this.state.email);

    return gqlMutation(this.mutation, (mutateFunc) => (
      <div className="login-panel">
        <div className="panel-body">
          {this.render_welcome && (
            <>
              <p className="login-text">Welcome to Crosslake's</p>
              <p className="login-text">Corsis&#0174; application!</p>
            </>
          )}
          {error && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
              {error}
            </MessageBar>
          )}
          {completed && (
            <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
              {completed}
              If an account was found, you will receive an email shortly with reset instructions
            </MessageBar>
          )}
          <form onSubmit={() => this.submit(mutateFunc)}>
            {this.formInner()}
            {!completed && (
              <div className="text-center">
                <PrimaryButton
                  data-testid="login-btn"
                  style={{ backgroundColor: this.props.extraVars.login_btn_color }}
                  type="submit"
                  disabled={!canSubmit}
                >
                  {this.submit_label}
                </PrimaryButton>
              </div>
            )}
            {this.submit_label == "Login" && (
              <>
                <p className="login-or">OR</p>
                <SocialLoginForms />
              </>
            )}
            <div className="text-center">
              <a className="bottom" onClick={() => this.props.onChangeView()} data-testid="changing-view-button">
                {this.link_label}
              </a>
            </div>
          </form>
        </div>

        <div className="text-center text-darkgray text-14 mt-40">
          For the best experience use
          <a
            className="text-blue font-bold ml-4 mr-0"
            target="_blank"
            href="https://www.google.com/chrome/"
            rel="noreferrer"
          >
            Google Chrome
          </a>
        </div>
      </div>
    ));
  }
}
