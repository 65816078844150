import React from "react";
import { disabledIconStyle, disabledStyle, hoverStyle, iconStyle, svgStyle } from "react-google-button/src/styles";

const libBaseStyle = {
  height: "50px",
  width: "240px",
  border: "none",
  textAlign: "center",
  color: "rgba(0, 0, 0, 0.54)",
  verticalAlign: "center",
  boxShadow: "0 2px 4px 0 rgba(0,0,0,.25)",
  fontSize: "16px",
  lineHeight: "48px",
  display: "block",
  borderRadius: "1px",
  transition: "background-color .218s, border-color .218s, box-shadow .218s",
  fontFamily: "Roboto,arial,sans-serif",
  cursor: "pointer",
  userSelect: "none"
};
const baseStyle = {
  ...libBaseStyle,
  height: "42px",
  width: "unset"
};

const MicrosoftIcon = (disabled) => {
  return (
    <div style={!disabled ? iconStyle : { ...iconStyle, ...disabledIconStyle }}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="46px"
        height="46px"
        viewBox="0 0 46 46"
        style={svgStyle}
      >
        <defs>
          <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-1">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.168 0"
              in="shadowBlurOuter1"
              type="matrix"
              result="shadowMatrixOuter1"
            />
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter2" />
            <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.084 0"
              in="shadowBlurOuter2"
              type="matrix"
              result="shadowMatrixOuter2"
            />
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1" />
              <feMergeNode in="shadowMatrixOuter2" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <rect id="path-2" x="0" y="0" width="40" height="40" rx="2" />
        </defs>
        <g id="Google-Button" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="9-PATCH" transform="translate(-608.000000, -160.000000)" />
          <path fill="#f25022" d="M13 11h9v9h-9z" />
          <path fill="#00a4ef" d="M13 21h9v9h-9z" />
          <path fill="#7fba00" d="M23 11h9v9h-9z" />
          <path fill="#ffb900" d="M23 21h9v9h-9z" />
        </g>
      </svg>
    </div>
  );
};

const getStyle = (hovered, disabled, propStyles = {}) => {
  if (hovered) {
    return { ...baseStyle, ...hoverStyle, ...propStyles };
  }
  if (disabled) {
    return { ...baseStyle, ...disabledStyle, ...propStyles };
  }
  return { ...baseStyle, ...propStyles };
};

export const MicrosoftLoginButton = ({ onClick, ...props }) => {
  const label = "Sign in with Microsoft";
  const [hovered, setHover] = React.useState(false);
  return (
    <div
      role="button"
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={getStyle(hovered, false)}
      {...props}
    >
      <MicrosoftIcon />
      <span>{label}</span>
    </div>
  );
};
