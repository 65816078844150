import { ResetPasswordMutation } from "gql/mutations/auth.graphql";
import { MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import React, { useState } from "react";
import { gqlMutation } from "../common/lib";
import { getParameterByName } from "../common/utils";
import { LoginLayout } from "./LoginLayout";
import { RequirementAlgs, RequirementValidator } from "../common/components/RequirementValidator";

function ResetPassword(props) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const handleSubmit = (mutateFunc) => {
    if (password != passwordRepeat) {
      return setError("The passwords do not match");
    }

    const token = getParameterByName("reset_password_token");
    mutateFunc({ variables: { password, token } }).then(({ data }) => {
      const res = data.reset_password;
      if (res.errors) {
        setError(res.errors.join(<br />));
      }
      if (res.success) {
        setSuccess(true);
      }
    });
  };

  const renderForm = (mutateFunc) => {
    const canSubmit = () => {
      return (
        !!password &&
        password === passwordRepeat &&
        RequirementAlgs.minLength(password, 8) &&
        RequirementAlgs.oneUppercase(password) &&
        RequirementAlgs.oneNumber(password)
      );
    };

    return (
      <form onSubmit={() => handleSubmit(mutateFunc)}>
        <TextField
          type="password"
          label="Password"
          value={password}
          onChange={(e, v) => setPassword(v ?? "")}
          data-testid="password"
        />
        <TextField
          type="password"
          label="Repeat Password"
          value={passwordRepeat}
          onChange={(e, v) => setPasswordRepeat(v ?? "")}
          data-testid="repeat-password"
        />
        <div className="text-center">
          <PrimaryButton type="submit" style={{ padding: 0 }} disabled={!canSubmit()} data-testid="login-btn">
            Reset Password
          </PrimaryButton>
        </div>
      </form>
    );
  };

  const renderSuccess = () => (
    <>
      <MessageBar messageBarType={MessageBarType.success}>Your password has been successfully updated.</MessageBar>
      <div className="text-center">
        <PrimaryButton
          onClick={() => (window.location.href = "/")}
          data-testid="login-btn"
          style={{ padding: 0 }}
          type="submit"
        >
          Go to Login Page
        </PrimaryButton>
      </div>
    </>
  );

  return (
    <LoginLayout>
      <div className="login-panel">
        <div className="corsis-logo" />
        <div className="panel-body">
          <h1>Change Your Password</h1>
          <p>
            Password requirements: <br />
            <RequirementValidator requirementAlg={() => RequirementAlgs.oneUppercase(password)}>
              - At least one uppercase character [A-Z] <br />
            </RequirementValidator>
            <RequirementValidator requirementAlg={() => RequirementAlgs.oneNumber(password)}>
              - At least one number [0-9] <br />
            </RequirementValidator>
            <RequirementValidator requirementAlg={() => RequirementAlgs.minLength(password, 8)}>
              - Minimum of 8 characters <br />
            </RequirementValidator>
          </p>
          {error && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
              {error}
            </MessageBar>
          )}
          {gqlMutation(ResetPasswordMutation, (mutateFunc) => (success ? renderSuccess() : renderForm(mutateFunc)))}
        </div>
      </div>
    </LoginLayout>
  );
}

export default ResetPassword;
