import { gqlMutation } from "@/common/lib";
import { validateEmail } from "@/common/utils";
import { ValidateEmailMutation } from "gql/mutations/auth.graphql";
import { PrimaryButton, TextField } from "office-ui-fabric-react";
import React, { useState } from "react";
import { ERROR_MESSAGES } from "./constants";

export default function EmailForm({ onSubmit, onError }) {
  const [email, setEmail] = useState("");

  const handleSubmit = (e, mutateFunc) => {
    e.preventDefault();

    mutateFunc({ variables: { email } })
      .then(({ data }) => {
        onSubmit(data.validate_email);
      })
      .catch(() => {
        onError(ERROR_MESSAGES.wrong_email);
      });
  };

  const disabled = !!validateEmail(email);

  return gqlMutation(ValidateEmailMutation, (mutateFunc) => (
    <form onSubmit={(e) => handleSubmit(e, mutateFunc)}>
      <div className="mb-20">Enter email address below and click Next to access your account.</div>
      <TextField
        name="email"
        autoFocus
        label="Email"
        value={email}
        onChange={(e, v) => setEmail(v ?? "")}
        data-testid="field-email"
      />
      <PrimaryButton className="mt-20" type="submit" disabled={disabled} data-testid="next-button">
        Next
      </PrimaryButton>
    </form>
  ));
}
