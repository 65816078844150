import { gqlMutation } from "@/common/lib";
import { LoginMutation } from "gql/mutations/auth.graphql";
import { Checkbox, PrimaryButton, TextField } from "office-ui-fabric-react";
import React, { useState } from "react";
import { ERROR_MESSAGES } from "./constants";

interface IProps {
  email: string
  redirectTo?: string
  onError: (value: string) => void
}

export default function PasswordForm({ email, redirectTo, onError }: IProps) {
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = (e, mutateFunc) => {
    e.preventDefault();

    mutateFunc({ variables: { email, password, remember_me: rememberMe } })
      .then(({ data }) => {
        window.location.href = redirectTo ?? data.login.redirect_uri;
      })
      .catch(() => onError(ERROR_MESSAGES.wrong_password));
  };

  const disabled = !password;

  return gqlMutation(LoginMutation, (mutateFunc) => (
    <form onSubmit={(e) => handleSubmit(e, mutateFunc)}>
      <TextField
        data-testid="password"
        name="user_password"
        label="Password"
        type="password"
        value={password}
        onChange={(e, v) => setPassword(v ?? "")}
      />
      <div className="mt-16" data-testid="remember-me-checkbox">
        <Checkbox label="Remember me" checked={rememberMe} onChange={(e, v) => setRememberMe(v ?? false)} />
      </div>

      <PrimaryButton className="mt-20" type="submit" disabled={disabled} data-testid="login-button">
        Login
      </PrimaryButton>
    </form>
  ));
}
