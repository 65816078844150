import React, { useState } from "react";
import SocialLoginForms from "../SocialLoginForms";
import PasswordForm from "./PasswordForm";
import "./LoginForms.scss";
import { ForgotComponent } from "../LoginPage/ForgotComponent";

interface IProps {
  email: string
  requireOauth: boolean
  redirectTo?: string
  onError: (e: string) => void
}

export default function LoginForms({ email, requireOauth, redirectTo, onError }: IProps) {
  const [mode, setMode] = useState<"forgot" | "login">("login");

  const renderPasswordForm = () => {
    const params = {
      client_email: email,
      ...(redirectTo && { redirect_to: redirectTo })
    };

    return (
      <>
        <div className="mb-16">
          {requireOauth
            ? "Choose from one of the below sign in options to access your account."
            : "Enter your account password or choose from one of the below sign in options."}
        </div>

        {!requireOauth && (
          <>
            <PasswordForm email={email} redirectTo={redirectTo} onError={onError} />
            <div className="password-form-span">OR</div>
          </>
        )}

        <SocialLoginForms params={params} />

        <div className="text-center">
          <a className="forgot-password" onClick={() => setMode("forgot")} data-testid="changing-view-button">
            Forgot password
          </a>
        </div>
      </>
    );
  };

  const renderForgotPasswordForm = () => {
    return (
      <ForgotComponent extraVars={{}} onChangeView={() => setMode("login")} />
    );
  };

  return (
    <>
      {mode == "login" && renderPasswordForm()}
      {mode == "forgot" && renderForgotPasswordForm()}
    </>
  );
}
