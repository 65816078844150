import gql from "graphql-tag";
import React from "react";
import { gqlMutation } from "../../common/lib";
import { Authenticator2FAComponent } from "./Authenticator2FAComponent";
import { SMS2FAComponent } from "./SMS2FAComponent";

export default class M2FAPage extends React.Component {
  query = gql`
    mutation trigger {
      trigger_2fa {
        id
        m2fa_method
      }
    }
  `;

  state = {
    Component: null as any
  };

  init = false;
  setComponent(res) {
    let Component: any;
    switch (res.data.trigger_2fa.m2fa_method) {
      case "sms":
        Component = SMS2FAComponent;
        break;
      default:
        Component = Authenticator2FAComponent;
    }
    this.setState({ Component: <Component /> });
  }

  render() {
    return gqlMutation(this.query, (mutateFunc) => {
      if (!this.init) {
        mutateFunc().then((res) => this.setComponent(res));
        this.init = true;
      }
      const { Component } = this.state;
      return Component;
    });
  }
}
