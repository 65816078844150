import React from "react";
import GoogleButton from "react-google-button";
import { MicrosoftLoginButton } from "./MicrosoftLoginButton";

const getCsrfToken = (): string => {
  const el = document.querySelector("meta[name=csrf-token]");
  if (el) {
    return el.getAttribute("content") as string;
  }
  return ""; // As is the case with Cypress
};

interface ElementWithParentForm extends HTMLElement {
  parentNode: HTMLFormElement | null
}

export default function SocialLoginForms({ params = {} }) {
  const csrf = getCsrfToken();
  const qs = new URLSearchParams(params).toString();

  return (
    <>
      <form method="POST" action={`/users/auth/google_oauth2?${qs}`}>
        <input type="hidden" name="authenticity_token" value={csrf} />
        <GoogleButton className="login-button" type="light"
                      onClick={(e: React.MouseEvent<ElementWithParentForm>) => e.currentTarget.parentNode?.submit()} />
      </form>

      <form method="POST" action={`/users/auth/microsoft_graph?${qs}`}>
        <input type="hidden" name="authenticity_token" value={csrf} />
        <MicrosoftLoginButton
          className="login-button"
          onClick={(e) => e.currentTarget.parentNode.submit()}
          data-testid="microsoft-login-btn"
        />
      </form>
    </>
  );
}
