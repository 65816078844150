import { ConfirmMutation } from "gql/mutations/auth.graphql";
import { MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import React, { useState } from "react";
import { gqlMutation } from "../common/lib";
import { LoginLayout } from "./LoginLayout";
import { RequirementAlgs, RequirementValidator } from "../common/components/RequirementValidator";

const joinBr = (array: any[]) => array.reduce((all, cur) => [...all, <br />, cur]);

const ConfirmationPage = () => {
  const [errors, setErrors] = useState(window.data?.error ? [window.data.error] : []);
  const [terminalError] = useState(Boolean(window.data?.error));

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [redirectTo, setRedirectTo] = useState("/");

  const handleSubmit = (mutateFunc) => {
    setErrors([]);
    const token = window.data.token;

    mutateFunc({ variables: { password, token } }).then(({ data }) => {
      data = data.reset_password;
      if (data.success) {
        setSuccess(true);
        setRedirectTo(data.redirect_to);
      } else {
        setErrors(data.errors ?? []);
      }
    });
  };

  const renderSuccess = () => {
    const redirect = () => (window.location.href = redirectTo);
    window.setTimeout(redirect, 0);
    return (
      <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
        Account activated successfully .
      </MessageBar>
    );
  };

  const canSubmit = () => {
    return (
      !!password &&
      password === confirmPassword &&
      RequirementAlgs.minLength(password, 8) &&
      RequirementAlgs.oneUppercase(password) &&
      RequirementAlgs.oneNumber(password)
    );
  };

  const renderForm = (mutateFunc) => {
    return (
      <form onSubmit={() => handleSubmit(mutateFunc)}>
        <div>
          <TextField
            name="user_password"
            label="Password"
            type="password"
            value={password}
            autoFocus
            onChange={(e, v) => setPassword(v ?? "")}
            data-testid="password"
          />
          <TextField
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e, v) => setConfirmPassword(v ?? "")}
            data-testid="password"
          />
        </div>
        <div className="text-center">
          <PrimaryButton type="submit" onClick={() => handleSubmit(mutateFunc)} disabled={!canSubmit()}>
            Submit
          </PrimaryButton>
        </div>
      </form>
    );
  };

  const showForm = !terminalError && !success;

  return (
      <LoginLayout withLinkedIn={false}>
        <div className="h-100 flex items-center justify-center login-panel">
          <div className="w-360px min-h-500px p-16 flex flex-col">
            <div className="text-32 text-darkgray">
              Welcome to Crosslake's
              <br />
              Corsis&#0174; application!
            </div>
            {gqlMutation(ConfirmMutation, (mutateFunc) => (
                <div className="flex-1 overflow-hidden">
                  <h1>Activate Your Account</h1>
                  <p>
                    Password requirements: <br />
                    <RequirementValidator requirementAlg={() => RequirementAlgs.oneUppercase(password)}>
                      - At least one uppercase character [A-Z] <br />
                    </RequirementValidator>
                    <RequirementValidator requirementAlg={() => RequirementAlgs.oneNumber(password)}>
                      - At least one number [0-9] <br />
                    </RequirementValidator>
                    <RequirementValidator requirementAlg={() => RequirementAlgs.minLength(password, 8)}>
                      - Minimum of 8 characters <br />
                    </RequirementValidator>
                  </p>
                  {errors.length > 0 && (
                      <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                        {joinBr(errors)}
                      </MessageBar>
                  )}
                  {success && renderSuccess()}
                  {showForm && renderForm(mutateFunc)}
                </div>
            ))}
          </div>
        </div>
      </LoginLayout>
  );
};

export default ConfirmationPage;
